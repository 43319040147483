#imagenTallerista {
	display: none;
	z-index: 3;
	position: absolute;
	top: 0;
	width: 100%;
	margin-top: 100px;
}
.botones-imagen-tallerista {
	background-color: white;
	color: black;
	border-radius: 50%;
	border: none;
	width: 40px;
	height: 40px;
	align-items: center;
	text-align: center;
	z-index: 4;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.imagen-tallerista-vertical {
	height: 600px;
}
.imagen-tallerista-horizontal{
	width: 100%;
	height: 600px;
}

@media screen and (max-width: 992px) {
	.imagen-tallerista-vertical {
		height: 400px;
	}
	.imagen-tallerista-horizontal{
		width: 100%;
		height: 400px;
	}
}

@media screen and (max-width: 768px) {
	.imagen-tallerista-vertical {
		height: 300px;
	}
	.imagen-tallerista-horizontal{
		width: 100%;
		height: 300px;
	}
}