/* Estilos dentro de caja negra*/
.caja-negra-footer-mobile {
	background-color: black;
}

.footer-contenedor-texto-negro {
	padding-bottom: 33px;
	padding-top: 20px;
}
.footer-register-title-mobile {
	font-size: 32px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.19;
	letter-spacing: 0.75px;
	color: white;
	font-family: "GothicMainDemi";
}

.footer-register-link-mobile {
	font-family: "GothicMainBook";
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.03;
	letter-spacing: 0.38px;
	color: white;
}

.caja-gris-footer-mobile {
	background-color: #333;
	padding-bottom: 20px;
}

/* Estilo dentro de caja gris*/

.footer-texto-gris-link-mobile {
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.27;
	letter-spacing: 0.31px;
	color: white;
	font-family: "GothicMainBook";
}
.footer-contenedor-links-mobile {
	padding-top: 17px;
	padding-bottom: 40px;
}

.footer-li-mobile {
	margin-bottom: 16px;
}

.footer-contenedor-redes-sociales-mobile {
	text-align: left;
	padding-left: 0px;
}

/*Estilos de idiomas*/

.footer-text-idiomas-mobile {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: 0.38px;
	color: white;
	font-family: "GothicMainBook";
	margin-right: 40px;
}
.footer-hr-mobile {
	background-color: white;
}

/*Estilos de datos MIEM*/
.footer-contenedor-logo-mobile {
	padding-right: 0px;
	margin-bottom: min(17px, 2%);
	padding-top: 15px;
}
.footer-texto-datos-miem-mobile {
	color: white;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	font-family: "GothicMainBook";
	font-size: 13px;
}
