.dia_negro {
	background-color: #d8d8d8;
	color: black;
}
.dia_blanco {
	background-color: white;
	color: black;
}
.dia_seleccionado {
	background-color: black;
	color: white;
}

.dia_container {
	display: flex;
	justify-content: center;
	cursor: pointer;
	width: 80%;
	font-size: 13px;
}
.row {
	display: flex;
	flex-direction: row;
}
.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-right: 15px;
	padding-left: 15px;
	padding-bottom: 5px;
	padding-top: 5px;
	border: 1px solid black;
	border-radius: 6px;
	min-height: 200px;
}

.element_container {
	padding-bottom: 10px;
	flex: 1;
	display: flex;
	justify-content: center;
	font-size: 13px;
}

.texto_mes {
	font-size: 13px;
	font-family: "GothicMainDemi";
	font-weight: bold;
	line-height: 1.55;
	letter-spacing: 0.6px;
	line-height: 1.38;
	letter-spacing: 0.5px;
	text-align: center;
}
