@media screen and (min-width: 576px) {
	.text-container-noticia {
		width: 540px;
	}
	.titulo-noticia {
		padding-top: 50px;
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 48px;
		margin-bottom: 30px;
		text-transform: uppercase;
		line-height: 1.13;
	}
	.clase-rara-noticia p {
		font-family: Georgia;
		font-size: 16.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.55;
		letter-spacing: 0.6px;
		text-align: left;
		align-content: center;
	}
	.clase-rara-noticia h2 {
		font-family: "GothicMainDemi";
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.41;
		letter-spacing: normal;

		margin-top: 60px;
		margin-bottom: 60px;
	}
	.title-mas-noticias {
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 32px;
		margin-bottom: 52px;
		text-transform: uppercase;
	}
}
@media screen and (max-width: 576px) {
	.titulo-noticia {
		padding-top: 23px;
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 29px;
		margin-bottom: 30px;
		text-transform: uppercase;
		line-height: 1.13;
	}
	.clase-rara-noticia p {
		font-family: Georgia;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.55;
		letter-spacing: 0.6px;
		text-align: left;
		align-content: center;
	}
	.clase-rara-noticia h2 {
		font-family: "GothicMainDemi";
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.41;
		letter-spacing: normal;

		margin-top: 40px;
		margin-bottom: 40px;
	}
	.title-mas-noticias {
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 29px;
		margin-bottom: 38px;
		text-transform: uppercase;
	}
	.noticia-fecha-home.card-text {
		border-left: 1px solid black;
		padding: 6px;
		height: 149px;
		min-width: 43px;
	}
}
.contenedor_markdown {
	color: black;
	display: flex;
	justify-content: center;
}

.clase-rara-noticia h4 {
	font-family: "GothicMainBook";
	font-size: 10.5px;
	font-weight: normal;
	font-stretch: normal;

	margin-top: 20px;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.titulo-compartir {
	font-family: "GothicMain-Demi" !important;
	font-size: 16.5px !important;
	font-weight: bold !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1 !important;
	letter-spacing: 0.39px !important;
	margin-top: 95px;
}

.clase-rara-noticia img {
	margin-top: 20px;
	margin-bottom: 20px;
	object-fit: cover;
	width: 100%;
}

.hr-mas-noticias {
	border: none;
	height: 1px;
	/* Set the hr color */
	color: black; /* old IE */
	background-color: black;
	margin-top: 60px;
}

.listado-mas-noticias {
	margin-bottom: 26px;
}
.contenedor-mas-noticias {
	margin-bottom: 20px;
}
.contenedor_markdown {
	min-height: 800px;
}

@font-face {
	font-family: "GothicMainDemi"; /*name to be used */
	src: url("./../../assets/Fonts-urw_gothic_L/urw_gothic_l_demi.ttf");
}
@font-face {
	font-family: "GothicMainBook";
	src: url("./../../assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}
