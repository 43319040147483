.artesano-img {
	border-radius: 6px;
	margin: 25px 30px 25.9px 22px;
	max-height: 312px;
}
.subtitulo-lateral {
	font-size: 15px;
	color: black;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
}
.texto-lateral {
	font-size: 13px;
	max-width: 255px;
	font-family: "GothicMainBook";
}
.contenedor-info-lateral {
	margin-bottom: 35px;
}
.contenedor-contacto {
	font-weight: bold;
}
@media screen and (min-width: 576px) {
	.imagen-perfil-artesano-vertical {
		border-radius: 6px;
		width: 100%;
		height: 312px;
		object-fit: cover;
	}
	.imagen-perfil-artesano-horizontal {
		border-radius: 6px;
		width: 100%;
		height: 312px;
	}
}
@media screen and (max-width: 576px) {
	.imagen-perfil-artesano-vertical {
		border-radius: 6px;
		width: 100%;
		height: 200px;
		/* object-fit: cover; */
	}
	.imagen-perfil-artesano-horizontal {
		border-radius: 6px;
		width: 100%;
		height: 200px;
	}
}

.link-component {
	font-size: 13px;
	font-family: "GothicMainBook";
	color: black;
	line-height: 1.38;
	letter-spacing: 0.5px;
}

.img-container-artesanos {
	margin-bottom: 26px;
}
.socialIcon-container {
	height: 30px;
	display: flex;
	align-items: center;
}
