.content_link {
	font-family: "GothicMainBook";
}

.contenedor_apoya {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}

.apoya_title {
	font-family: "GothicMainBook" !important;
	font-size: 13px !important;
	font-weight: normal !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.62 !important;
	letter-spacing: normal !important;
	white-space: pre-line !important;
	color: white;
}

.registro_footer {
	font-family: "GothicMainBook";
	width: 95px;
	height: 17px;
	margin: 21px 15px 0 2px;
	font-size: 16.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: 0.39px;
	color: #fff;
}
