.hr {
	border: none;
	height: 1px;
	/* Set the hr color */
	color: black; /* old IE */
	background-color: black;
	margin-top: 60px;
}

.title {
	font-family: "GothicMain-Demi"; /*name to be used */
	font-size: 29px;
	margin-bottom: 38px;
	text-transform: uppercase;
}

@media screen and (min-width: 576px) {
	.title {
		font-size: 32px;
		margin-bottom: 52px;
	}
}
