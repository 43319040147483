.limpiar_button {
	background-color: black;
	color: white;
	border-radius: 6px;
	font-family: "GothicMainBook";
	font-size: 13px;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 3px;
	padding-bottom: 3px;
	cursor: pointer;
}

.contenedor {
	margin-bottom: 13px;
}
