.error-404 {
  /* background-image: url("../../assets/Error404/Fondo404.png"); */
  height: 100vh;
  width: 100%;
  object-fit: contain;
  z-index: -1;
  position: absolute;
  top: 0;
}
.fondo-error {
  object-fit: cover;
}
.contenedor-texto-404 {
  margin-top: 125px;
  margin-bottom: 50vh;
}
.texto-error-404 {
  font-size: 18.5px;
  line-height: 1.3;
  letter-spacing: -0.1px;
  font-weight: bold;
  font-family: "GothicMainDemi";
  /* width: 290px; */
}
.texto404 {
  text-align: center;
  font-size: 48px;
  line-height: 1.13;
  font-weight: bold;
}

.boton-volver-home {
  background-color: black;
  color: white;
  font-family: "GothicMainBook";
  line-height: 1.2;
  letter-spacing: 0.58px;
  font-size: 15px;
  border-radius: 6px;
}
.contenedor-boton-volver {
  text-align: center;
}
.contenedor-parrafo-error {
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
