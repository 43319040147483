.container .gallery span + .row {
	margin-top: 50px;
}

/*Cards general rules */

.card-title {
	margin-bottom: 0.34rem !important;
}
p.card-text {
	margin: 0;
}
p.card-text + p.card-text {
	margin-top: 0.34rem;
}
.label {
	margin-right: -17px !important;
}
.gallery {
	min-height: 300px;
}

@media screen and (min-width: 576px) {
	.gallery span {
		width: 180px;
		height: 38px;
		font-family: GothicMainDemi;
		font-size: 32px;
		font-weight: normal;
		font-stretch: normal;
		text-transform: uppercase;
		line-height: 1.19;
		letter-spacing: normal;
		color: var(--black);
	}
	.gallery .container-fluid {
		margin-top: min(49px, 5%);
	}
	.gallery + .gallery {
		padding: 16px 0 26px 0;
	}
	.gallery {
		border-bottom: 1px solid black;

		padding-bottom: max(26px, 2%);
	}
	.ver-todo a p {
		margin: 0;
		margin-right: min(13px, 0.8em);
	}
	.ver-todo {
		position: relative;
		right: 0;
		bottom: 0;
		min-height: 15px;
		margin-top: min(32px, 3%);
		vertical-align: middle;
	}
}

@media screen and (max-width: 576px) {
	.gallery span {
		/* width: 180px; */
		/* height: 38px; */
		font-family: GothicMainDemi;
		font-size: 22px;
		font-weight: normal;
		font-stretch: normal;
		text-transform: uppercase;
		line-height: 1.73;
		letter-spacing: normal;
		color: var(--black);
	}
	.gallery .container-fluid {
		margin-top: min(49px, 5%);
	}
	.gallery + .gallery {
		padding-top: 2px;
	}
	.gallery {
		border-bottom: 1px solid black;

		padding-bottom: 12px;
		padding-top: 30px;
	}
	.ver-todo a p {
		margin: 0;
		margin-right: min(13px, 0.8em);
		font-size: 13px;
	}
	.ver-todo {
		position: relative;
		right: 0;
		bottom: 0;
		min-height: 15px;
		margin-top: 36px;
		vertical-align: middle;
	}
}

.row.artesanoRow {
	columns: 12;
	flex-wrap: nowrap;
}
.gallery .row {
	margin-top: 25px;
}

.container-fluid {
	padding: 0 !important;
}

a.link-noticia {
	color: black !important;
}

a.link-noticia:hover {
	color: black !important;
}

.ver-todo a {
	color: black !important;
	text-decoration: none !important;
	font-family: GothicMainBook;
	font-size: 16.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: 0.39px;

	max-height: 100%;

	display: inline-flex;
	white-space: nowrap;
	align-items: baseline;

	position: absolute;
	right: 0;
	bottom: 0;
}

.ver-todo a img {
	max-width: 24px;
	max-height: 13px;
}

.gallery:last-child .ver-todo {
	display: none;
}
.gallery:last-child {
	border: none;
	margin-bottom: min(30px, 5%);
}

.home-carousel-left-item {
	margin-right: 30px;
}

.title_link_home {
	color: black;
}
.title_link_home:hover {
	color: black;
	text-decoration: none;
}
