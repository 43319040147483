.input-label {
	font-size: 13px;
	font-weight: normal;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
	margin-left: 9px;
}

.component-box {
	padding-bottom: 37px;
	margin: 0px;
}

.box-title {
	font-weight: bold;
	font-size: 15px;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding-bottom: 20px;
	margin: 0px;
	color: black;
}
