@media screen and (min-width: 576px) {
	.text-container-historia {
		width: 540px;
	}
	.clase-rara-historia h2 {
		font-family: "GothicMainDemi";
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.41;
		letter-spacing: normal;

		margin-top: 20px;
		margin-bottom: 20px;
	}
	.clase-rara-historia p {
		font-family: Georgia;
		font-size: 16.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.55;
		letter-spacing: 0.6px;
		text-align: left;
		align-content: center;
	}
	.titulo-historia {
		padding-top: 50px;
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 48px;
		margin-bottom: 30px;
		text-transform: uppercase;
		line-height: 1.13;
	}
	.clase-rara-historia h4 {
		font-family: "GothicMainBook";
		font-size: 10.5px;
		font-weight: normal;
		font-stretch: normal;

		margin-top: 20px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.title-mas-historias {
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 32px;
		margin-bottom: 52px;
		text-transform: uppercase;
	}
}
@media screen and (max-width: 576px) {
	.clase-rara-historia h2 {
		font-family: "GothicMainDemi";
		font-size: 32px;
		font-weight: bold;
		line-height: 1.13;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.clase-rara-historia p {
		font-family: Georgia;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.55;
		letter-spacing: 0.6px;
		text-align: left;
		align-content: center;
	}
	.titulo-historia {
		padding-top: 23px;
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 29px;
		margin-bottom: 30px;
		text-transform: uppercase;
		line-height: 1.1;
	}
	.clase-rara-historia h4 {
		font-family: "GothicMainBook";
		font-size: 10.5px;
		font-weight: normal;
		font-stretch: normal;

		margin-top: 20px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.title-mas-historias {
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 29px;
		margin-bottom: 38px;
		text-transform: uppercase;
	}
}
.clase-rara-historia {
	color: black;
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.clase-rara-historia img {
	margin-top: 50px;
	margin-bottom: 30px;
	object-fit: cover;
	width: 100%;
}

.clase-rara-historia h4::before {
	content: "";
	position: absolute;
	/* top: 0; */
	/* left: 33%; */
	/* margin-left: 5px; */
	margin-top: -15px;
	width: 0;
	z-index: 1;
	height: 0px;
	border-bottom: solid 3px #333;
	border-left: solid 0px;
	border-right: solid 28px;
}

.hr-mas-historia {
	border: none;
	height: 1px;
	/* Set the hr color */
	color: black; /* old IE */
	background-color: black;
	margin-top: 60px;
}

.titulo-compartir {
	font-family: "GothicMain-Demi" !important;
	font-size: 16.5px !important;
	font-weight: bold !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1 !important;
	letter-spacing: 0.39px !important;
	margin-top: 95px;
}

@font-face {
	font-family: "GothicMainDemi"; /*name to be used */
	src: url("./../../assets/Fonts-urw_gothic_L/urw_gothic_l_demi.ttf");
}
@font-face {
	font-family: "GothicMainBook";
	src: url("./../../assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}
