.contenedor_general {
	margin-top: 89px;
}

.texto_resumen {
	font-size: 15px;
	font-weight: 400;
	font-family: Georgia;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: 0.6px;
	text-align: left;
	align-content: center;
}

.container {
	margin-top: 50px;
}

@media screen and (min-width: 992px) {
	.texto_resumen {
		font-size: 16.5px;
		font-weight: normal;
	}
}
