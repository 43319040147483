.RadioButtonMenu {
	background-color: #333 !important;
	color: white;
}

.RadioButtonMenu .dropdown-item {
	background-color: #333;
	color: white;
}

.texto-check-mobile {
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
}

.form-check > input {
	background-color: blue !important;
	color: aqua;
}

.boton-dropdown-mobile {
	background-color: white;
	color: black;
	border-color: white white black white;
	border-radius: 0px;
	width: 100%;
}

.dropdown > button {
	background-color: white;
	color: black;
	border-color: white white black white;
	border-radius: 0px;
	text-align: left;
	font-size: 13px;
	font-weight: bold;
}

.dropdown > button:hover {
	background-color: white;
	color: black;
	border-color: white white black white;
	border-radius: 0px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
	color: black !important;
	background-color: white !important;
	border-color: white white black white;
	box-shadow: none !important;
	border-radius: 0px;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: black !important;
	background-color: white !important;
	border-color: white white black white;
	box-shadow: none !important;
	border-radius: 0px;
}
.dropdown-item:focus,
.dropdown-item:hover {
	background-color: #333;
}
