.search_container {
	min-width: 80px;
	min-height: 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-left: 9px;
}

.search_bar {
	top: 0;
	left: 0;
	margin-top: 14px;
	width: 187px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px white;
	display: flex;
	align-items: center;
}

.search_bar_container {
	margin-top: 0;
	display: block;
	width: 100%;
	background-color: transparent;
	opacity: 1;
}

.search_bar_ir {
	width: 35px;
	height: 32px;
	border-radius: 4px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ir {
	font-family: GothicMainDemi;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: 0.35px;
	color: #333333;
}

.texto_buscador {
	font-family: GothicMainBook;
	font-size: 15px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.35px;
	line-height: 1.1;
}

.search_bar_icon {
	width: 14%;
	height: 100%;
	padding: 0.7px 31px 0 16px;
	border-right: solid 1px white;
	display: flex;
	align-items: center;
}

input[type="search"] {
	border: none;
	background-color: transparent;
	color: #fff;
	width: 100%;
	padding-left: 5%;
}

input[type="search"]:focus {
	outline: none;
}

#mobileSearchIcon {
	margin-bottom: 5px;
	margin-right: 12px;
}

/* Mobile Search */
@media screen and (min-width: 991px) {
	.search_bar {
		width: 380px;
		height: 52px;
		position: absolute;
		top: 2%;
		/* right: 40%; */
		left: 60%;
	}

	.search_bar_container {
		display: block;
		position: absolute;
		width: 100%;
		height: 100vh;
		left: 0;
		right: 0;
		background-color: #000;
		opacity: 0.8;
		margin-top: 106vh;
	}

	.search_container {
		min-width: 20px;
		min-height: 40px;
		margin-left: 20px;
		align-items: center;
	}
}
