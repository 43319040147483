.titulo-institucional {
	padding-top: 50px;
	font-family: "GothicMain-Demi"; /*name to be used */
	font-size: 48px;
	margin-bottom: 30px;
	text-transform: uppercase;
	line-height: 1.38;
	color: white;
}

.imagen-fondo {
	height: 900px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
}
.contenido-seccion {
	position: relative;
	color: black;
	text-align: left;
}

.enlace-lateral {
	color: black;
}
.enlace-lateral:hover {
	color: black;
	text-decoration: none;
}

@media screen and (min-width: 576px) {
	.markdown-general {
		padding: 20px;
		color: black;
	}
	.main-text-institucional h4 {
		/* font-family: "GothicMainBook"; */
		font-family: "GothicMainDemi";
		font-size: 16.5px;
		line-height: 1.55;
		font-weight: bold;

		padding-top: 31px;
		margin-left: 28px;
		margin-right: 30px;
	}
	.main-text-institucional p {
		font-family: Georgia;
		font-size: 16.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.55;
		letter-spacing: 0.6px;
		text-align: left;
		align-content: center;
		margin-left: 28px;
		margin-right: 30px;
		padding-top: 21px;
	}
	.main-text-institucional h2 {
		font-family: "GothicMainDemi";
		font-size: 32px;
		font-weight: bold;
		line-height: 1.41;
		text-align: center;

		padding-top: 60px;
		margin-left: 28px;
		margin-right: 30px;
	}
}

@media screen and (max-width: 576px) {
	.markdown-general {
		padding: 10px;
		color: black;
	}
	.main-text-institucional h4 {
		/* font-family: "GothicMainBook"; */
		font-family: "GothicMainDemi";
		font-size: 16.5px;
		line-height: 1.55;
		font-weight: bold;

		padding-top: 31px;
		margin-left: 16px;
		margin-right: 16px;
	}
	.main-text-institucional p {
		font-family: Georgia;
		font-size: 16.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.55;
		letter-spacing: 0.6px;
		text-align: left;
		align-content: center;
		margin-left: 16px;
		margin-right: 16px;
		padding-top: 21px;
	}
	.main-text-institucional h2 {
		font-family: "GothicMainDemi";
		font-size: 32px;
		font-weight: bold;
		line-height: 1.41;
		text-align: center;

		padding-top: 60px;
		margin-left: 16px;
		margin-right: 16px;
	}
	.lateral-items-institucional span {
		background-color: white;
		border-radius: 6px;
		display: flex;
		font-family: "GothicMainBook";
		font-size: 13px;
		line-height: 1.38;
		letter-spacing: 0.5px;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 3px;
		padding-bottom: 3px;
		cursor: pointer;
		width: 100%;
		margin-right: 10px;
	}
}

.links-institucionales {
	color: white;
}

.main-text-institucional {
	background-color: white;
	border-radius: 6px;
	width: 100%;
}

.lateral-items-institucional span {
	border-radius: 6px;
	font-family: "GothicMainBook";
	font-size: 13px;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 3px;
	padding-bottom: 3px;
	cursor: pointer;
}

.lateral_intitucional_blanco {
	background-color: white;
	color: black;
}

.lateral_intitucional_negro {
	background-color: black;
	color: white;
	border: 1px solid white;
}

.lateral-items-institucional {
	display: flex;
	margin-bottom: 13px;
}

.lateral-items-institucional-mobile span {
	background-color: white;
	border-radius: 6px;
	font-family: "GothicMainBook";
	font-size: 13px;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 3px;
	padding-bottom: 3px;
	cursor: pointer;
}

.lateral-items-institucional-mobile {
	margin-bottom: 16px;
}

.contenido-seccion-mobile {
	position: relative;
	color: black;
	text-align: left;
}

.institucional-row-mobile {
	display: flex;
	flex-wrap: wrap;
}

.institucional-contenedor-texto-mobil {
	padding-top: 66px;
}

.institucional-tags-mobile {
	margin-bottom: 50px;
	margin-top: 32px;
}

.titulo-institucional-mobile {
	color: white;
	text-transform: uppercase;
}

.imagen-fondo-mobile {
	height: 432px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
}

.institucional-imagen-fondo-mobile {
	height: 432px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
}
