@media screen and (max-width: 576px) {
  .taller-disenio-contenedor {
    padding: 30px 15px 90px 15px;
  }
}
@media screen and (min-width: 576px) {
  .taller-disenio-contenedor {
    padding: 30px 46px 90px 63px;
  }
}

.lateral-items-talleres span {
  background-color: white;
  border-radius: 6px;
  display: flex;
  font-family: "GothicMainBook";
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: 0.5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.lateral-items-talleres {
  border-radius: 6px;
  display: flex;
  margin-bottom: 13px;
}
