.carousel-item:hover {
	cursor: pointer;
}
.heroDiv {
	min-height: 100vh !important;
	min-height: 100dvh !important;
	position: relative;
	/* height: 100dvh !important; */
}
.carousel-inner {
	height: 100% !important;
}

.carousel-control-prev,
.carousel-control-next {
	width: 100%;
	margin-top: 45%;
	margin-left: 0;
	opacity: 1;
}

#heroControls {
	position: absolute;
	z-index: 50;
	/* padding: 0; */
	/* margin-top: 45%; */
	display: flex;
	justify-content: space-between;
	max-width: 1140px;
	width: 100%;
	bottom: 60px;
}
.carouselArrowPrev {
}

.carouselArrowNext {
	margin-right: 20px;
}

.carouselArrowPrev:hover,
.carouselArrowNext:hover {
	border-bottom: 1px solid white;
	cursor: pointer;
}

/*margin: 0% 0% 17.8% 0%;
  left: 12.1% !important;*/

@media screen and (min-width: 1200px) {
	.carousel-caption h2.carousel-subtitle {
		width: 445px;
		height: 25.5px;
		text-align: left;
		font-family: GothicMainBook;
		font-size: 16.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: 0.6px;
		color: white;
		margin-bottom: 0;
		margin-top: 0;
	}
	.carousel-caption h1.carousel-title {
		width: 540px;
		font-family: GothicMainDemi;
		font-size: 48px;
		text-align: left;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		text-transform: uppercase !important;
		line-height: 1.13;
		letter-spacing: normal;
		color: white;
		margin-bottom: 1.6%;
		margin-top: 0;
	}
	.carousel-caption {
		width: inherit;
		height: inherit;
		left: inherit !important;
		right: inherit !important;
		bottom: 35% !important;
	}
}
@media screen and (max-width: 1200px) {
	.carousel-caption h2.carousel-subtitle {
		width: 300px;
		height: 25.5px;
		text-align: left;
		font-family: GothicMainBook;
		font-size: 16.5px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: 0.6px;
		color: white;
		margin-bottom: 0;
		margin-top: 0;
	}
	.carousel-caption h1.carousel-title {
		width: 380px;
		font-family: GothicMainDemi;
		font-size: 42px;
		text-align: left;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		text-transform: uppercase !important;
		line-height: 1.13;
		letter-spacing: normal;
		color: white;
		margin-bottom: 1.6%;
		margin-top: 0;
	}
	.carousel-caption {
		width: inherit;
		height: inherit;
		left: inherit !important;
		right: inherit !important;
		bottom: 35% !important;
	}
}

@media screen and (max-width: 576px) {
	.carousel {
		/* z-index: 2; */
	}
	.hero-imagen-destacada {
		width: 100%;
		height: 100%;
		/* height: calc(100vh - 100px); */
		object-fit: cover;
	}
	.carousel-caption h1.carousel-title {
		width: 90%;
		font-family: GothicMainDemi;
		font-size: 29px;
		text-align: left;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		text-transform: uppercase !important;
		line-height: 1.13;
		letter-spacing: normal;
		color: white;
		margin-bottom: 1.6%;
		margin-top: 0;
	}
	.carousel-caption h2.carousel-subtitle {
		width: 240px;
		height: 25.5px;
		text-align: left;
		font-family: GothicMainBook;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.35;
		letter-spacing: 0.6px;
		color: white;
		margin-bottom: 0;
		margin-top: 0;
	}
	.carousel-caption {
		width: inherit;
		height: inherit;
		left: inherit !important;
		right: inherit !important;
		bottom: 15% !important;
	}
}

@media screen and (min-width: 576px) {
	.carousel {
		margin-bottom: 30px;
		z-index: 2;
	}
	.carousel-caption {
		width: inherit;
		height: inherit;
		left: inherit !important;
		right: inherit !important;
		bottom: 35% !important;
	}
}

/*
.crousel-item {
    opacity: 0;
    transition: opacity 0.2s ;
  }
  .carousel-item.active {
    opacity: 1;
    transition: opacity 0.2s ;
  }
  */
