.tallerista-card {
  max-height: 350px;
  border: unset;
  margin-top: 20px;
  cursor: pointer;
}

.nombre-tallerista {
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 13px !important;
  font-family: "GothicMainDemi" !important;
  padding-top: 7px !important;
  text-align: center !important;
  line-height: 1.3 !important;
}

.taller-titulo {
  font-size: 16.5px;
  line-height: 1.55;
  text-transform: uppercase;
  font-family: "GothicMainDemi";
}

.tallerista-portada {
  border-radius: 6px;
  object-fit: cover;
  max-height: 312px;
}

@media screen and (max-width: 576px) {
  .taller-titulo {
    font-size: 16.5px;
    line-height: 1.55;
    text-transform: uppercase;
    font-family: "GothicMainDemi";
    text-align: center;
  }
}
@media screen and (min-width: 576px) {
  .taller-titulo {
    font-size: 16.5px;
    line-height: 1.55;
    text-transform: uppercase;
    font-family: "GothicMainDemi";
  }
}
