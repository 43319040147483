.wrapper {
	display: flex;
	margin-bottom: 100px;
}
.lateral-menu {
	font-family: "GothicMain";
	color: black;
	padding-left: 0px !important;
	display: flexbox;
	margin-bottom: 30px;
	flex-direction: row;
}

@media screen and (min-width: 576px) {
	.lateral-menu {
		padding-right: 20px;
	}
}

.hr-main-layout {
	border: none;
	height: 1px;
	/* Set the hr color */
	color: black; /* old IE */
	background-color: black;
}

.main_layout_container {
	min-height: 800px;
}

@media screen and (min-width: 576px) {
	.title {
		padding-top: 50px;
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 48px;
		line-height: 1.1;
		text-transform: uppercase;
	}
}
@media screen and (max-width: 576px) {
	.title {
		padding-top: 23px;
		font-family: "GothicMain-Demi"; /*name to be used */
		font-size: 29px;
		line-height: 1.1;
		text-transform: uppercase;
	}
}

.title-mobile {
	padding-top: 23px;
	font-family: "GothicMain-Demi"; /*name to be used */
	font-size: 29px;
	line-height: 1.1;
}
.main-content {
	padding-right: 0px !important;
}

@font-face {
	font-family: "GothicMain"; /*name to be used */
	src: url("../../assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}

@font-face {
	font-family: "GothicMain-Demi"; /*name to be used */
	src: url("../../assets/Fonts-urw_gothic_L/urw_gothic_l_demi.ttf");
}
