@media screen and (min-width: 576px) {
  .aviso-descarga {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 14%;
    z-index: 1;
    opacity: 0.9;
    border-radius: 8px;
    background-color: #f5f5f5;
    width: 55%;
    height: 30%;
    max-width: 540px;
    max-height: 155px;

    text-align: center;
  }
  .mensaje-descarga {
    display: inline-block;

    margin: 0 0 4px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-family: GothicMainDemi;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.1px;
    text-align: center;
    color: var(--black);
  }
  .sub-mensaje-descarga {
    display: inline-block;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-family: GothicMainBook;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: 0.47px;
    text-align: center;
    color: var(--black);
  }
  .black-button {
    width: 17%;
    height: 20%;
    bottom: 0;
    margin-top: 5%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: black;
    color: white;
    text-align: center;
    border: none;
  }
}
@media screen and (max-width: 576px) {
  .aviso-descarga {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40%;
    z-index: 1;
    opacity: 0.9;
    border-radius: 8px;
    background-color: #f5f5f5;
    width: 55%;
    height: 40%;
    max-width: 540px;
    /* margin-top: 130px; */
    text-align: center;
  }
  .mensaje-descarga {
    display: inline-block;

    margin: 0 0 4px;
    left: 0;
    right: 0;
    padding: 10px;
    font-family: GothicMainDemi;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.1px;
    text-align: center;
    color: var(--black);
  }
  .sub-mensaje-descarga {
    display: inline-block;
    left: 0;
    right: 0;
    font-family: GothicMainBook;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: 0.47px;
    text-align: center;
    color: var(--black);
    padding: 10px;
  }
  .black-button {
    /* width: 17%; */
    /* height: 20%; */
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10%;
    border-radius: 8px;
    background-color: black;
    color: white;
    text-align: center;
    border: none;
  }
}

.imagen-fondo-descarga {
  height: 100%;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 0;
}
.contenedor-descarga {
  width: 100vh;
  height: 100vh;
  max-height: 1000px;
}

.texto-descarga {
  margin-top: 5%;
}
