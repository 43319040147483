.historia-card {
	border: none !important;
	border-radius: 6px;
	cursor: pointer;
	margin-bottom: 30px;
}

.imagen-historia-vertical-mobile {
	border-radius: 6px;
	height: 440px;
	object-fit: cover;
}
.imagen-historia-horizontal-mobile {
	border-radius: 6px;
	/* height: 200px; */
	object-fit: cover;
}

.imagen-historia {
	border-radius: 6px;
	height: 312px;
	object-fit: cover;
}

.video-historia {
	border-radius: 6px;
	height: 312px;
	object-fit: cover;
	padding: 0px;
	border-bottom: none;
}

.titulo-card {
	font-size: 16.5px !important;
	margin-left: 0px;
	padding-left: 0px;
	font-family: "GothicMainDemi";
	font-weight: bold;
}
.historia-description {
	padding-left: 0px !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.texto-historia-view {
	font-family: Georgia;
	font-size: 16.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: 0.6px;
	/* height: 104px; */
}

.categoria-historia {
	font-family: "GothicMainBook";
	height: 18px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding-right: 15px;
}
.historia-description.card-body > p {
	max-height: 145px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.categoria-historia a {
	color: black;
}
.categoria-historia a:hover {
	text-decoration: none !important;
}
