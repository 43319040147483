.SimpleMenu {
  background-color: #333;
  color: white;
}

.SimpleMenu .dropdown-item {
  /* background-color: #333; */
  color: white;
}

.texto-simple-mobile {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  font-family: "GothicMain";
}

.dropdown-item-simple-menu {
  white-space: normal !important;
  padding: 0px;
}
