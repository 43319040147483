.contenedor-general {
	display: flex;
	position: relative;
	flex-wrap: nowrap;
	width: 100%;
	outline: 0;
	max-height: 600px;
}

.contenedor-botones {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0px;
}

.contenedor-imagen-total {
	display: flex;
	justify-content: center;
}

.div-fantasma {
	height: 40px;
}
