.boton-paginacion {
	background-color: white;
	font-family: "GothicMain";
	border: none;
	color: black;
	font-size: 16.5px;
	box-shadow: none !important;
}
.button-end {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.button-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.boton-paginacion:hover {
	background-color: white;
	font-family: "GothicMain";
	border: none;
	color: black;
	font-size: 16.5px;
	font-weight: bolder;
	text-decoration: underline;
}

.boton-paginacion-active {
	background-color: white;
	font-family: "GothicMain";
	border: none;
	color: black !important;
	font-size: 16.5px;
	font-weight: bolder;
	text-decoration: underline;
	box-shadow: none !important;
}

.componente-paginacion {
	margin-bottom: 48px;
}

.contenedor {
	display: flex;
}
.contenedor-paginacion {
	padding-right: 0px;
}
.contenedor-paginacion-mobile {
	padding-right: 0px;
	padding-left: 0px;
}
.contenedor-mostrar-mas-mobile {
	align-items: center;
	align-content: center;
	text-align: center;
}

@font-face {
	font-family: "GothicMain"; /*name to be used */
	src: url("../../../assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}
