.CheckboxMenu {
	background-color: #333;
	color: white;
}

.CheckboxMenu .dropdown-item {
	/* background-color: #333; */
	color: white;
}

.texto-check-mobile {
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
	font-family: "GothicMain";
}

input[type="checkbox"],
input[type="radio"] {
	/* box-sizing: border-box; */
	padding: 0;
	accent-color: #333;
	height: 13px;
	width: 13px;
}

.dropdown-toggle::after {
	display: none;
	/* content: "^"; */
}

.dropdown-closed {
	content: "^";
}
.arrow-flip-drop {
	transform: rotate(180deg) !important;
	/* color: red; */
}
.flecha-algo {
	float: right;
}

.dropdown-item-checkbox-menu {
	white-space: pre !important;
	/* padding: 0px; */
	padding-left: 10px;
}

.todo-width {
	width: 100%;
}
