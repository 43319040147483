.search-bar-noticias {
    /* width: 380px; */
    height: 52px;
    border-radius: 4px;
    border: solid 1px black;
    display: flex;
    align-items: center;
    /* position: absolute; */
    /* top: 2%; */
    /* right: 15%; */
  }
  
  .search-bar-container-noticias {
    display: block;
    /* position: absolute; */
    width: 100%;
    /* height: 100vh; */
    left: 0;
    right: 0;
    /* background-color: #000; */
    opacity: 0.8;
    margin-top: max(11px, 1vh);
  }
  /*z-index: 0;*/
  
  .search-bar-icon-noticias {
    width: 14%;
    height: 100%;
    padding: 0.7px 36px 0 16px;
    border-right: solid 1px black;
    display: flex;
    align-items: center;
  }
  
  .search-container-noticias {
    min-width: 40px;
    min-height: 40px;
  }
  .search-input-noticias{
    border: none;
    background-color: transparent;
    color: black;
    width: 100%;
    padding-left: 5%;
  }
  
  
  .search-input-noticias:focus {
    outline: none;
  }
  