#aficheGrande {
	display: none;
	z-index: 5;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}
.aficheGrandeShadow {
	display: block;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #000;
	opacity: 0.8;
	margin-top: max(3%, 45px);
}
.imagen-afiche-grande {
	width: min(100%, 500px);
	height: min(100%, 300px);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	margin-top: max(6%, 74px);
}
.botones-pdf-grande {
	background-color: white;
	color: black;
	border-radius: 50%;
	border: none;
	width: 40px;
	height: 40px;
	align-items: center;
	text-align: center;
	position: absolute;
	z-index: 4;
}

.boton-cerrar-pdf-grande {
	top: 0;
	right: 0;
	margin-left: auto;
	margin-right: 10%;
	margin-top: max(6%, 74px);
}
