.contenedor-boton-mostrar-mas {
	margin-top: 10px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
}

.card.resultado-card:last-child {
	margin-bottom: min(80px, 8%);
}

.res-title span {
	text-transform: uppercase;
	letter-spacing: normal;
	color: var(--black);
	padding-top: 23px;
	font-family: "GothicMain-Demi"; /*name to be used */
	font-size: 29px;
	line-height: 1.1;
}

.res-title {
	width: 100%;
	margin-top: min(50px, 5%);

	border-bottom: 1px solid black;
	padding-bottom: 6px;
}

.results {
	min-height: 10%;
	min-width: 10%;
	margin-top: 15px;
}

.card.resultado-card + .card.resultado-card {
	margin-top: 15px;
}

@media screen and (min-width: 576px) {
	.res-title {
		width: 100%;
		margin-top: min(50px, 5%);
		margin-bottom: min(26px, 4%);
	}

	.res-title span {
		text-transform: uppercase;
		font-family: GothicMainDemi;
		font-size: 48px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.13;
		letter-spacing: normal;
		color: var(--black);
	}

	.results {
		min-height: 10%;
		min-width: 10%;
	}

	.card.resultado-card + .card.resultado-card {
		margin-top: min(26px, 3%);
	}
}
