.card-libro {
	border: 1px solid black;
	margin-bottom: 26px;
	border-radius: 0px;
}

@media screen and (min-width: 1200px) {
	.img-portada {
		border-radius: 6px;
		margin-bottom: 20px;
		/* max-width: 207px; */
		/* max-height: 207px; */
		cursor: pointer;
	}
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
	.img-portada {
		border-radius: 6px;
		margin-bottom: 20px;
		/* max-width: 170px; */
		/* max-height: 170px; */
		cursor: pointer;
	}
}

@media screen and (max-width: 992px) {
	.img-portada {
		border-radius: 6px;
		margin-bottom: 20px;
		/* max-width: 207px; */
		/* max-height: 207px; */
		cursor: pointer;
	}
	.libro-contenedor-imagen {
		text-align: center;
		padding: 23px 23px 0 23px;
	}
}

@media screen and (min-width: 992px) {
	.libro-contenedor-imagen {
		text-align: center;
		padding: 23px 23px 0 23px;
	}
}

@media screen and (max-width: 768px) {
	.img-portada {
		border-radius: 6px;
		margin-bottom: 10px;
		/* max-width: 134px; */
		/* max-height: 207px; */
		cursor: pointer;
	}
	.libro-contenedor-imagen {
		text-align: center;
		padding: 14px 14px 0 14px;
	}
}

@media screen and (max-width: 576px) {
	.img-portada {
		border-radius: 6px;
		margin-top: 10px;

		cursor: pointer;
	}
}
.libro-contenedor-imagen {
	text-align: center;
}
.boton-pdf {
	background-color: white;
	color: black;
	border: none;
	font-size: 16.5px;
	line-height: 1.7;
	letter-spacing: 0.39px;
	font-family: "GothicMain";
	padding: 0px;
}
.fecha-text {
	font-family: "GothicMainDemi";
}

.lateral-items-libros span {
	background-color: white;
	border-radius: 6px;
	font-family: "GothicMainBook";
	font-size: 13px;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 3px;
	padding-bottom: 3px;
	cursor: pointer;
	border: 1px solid black;
}

.lateral-items-libros {
	border-radius: 6px;
	display: flex;
	margin-bottom: 13px;
}
.contenedor-general-libros {
	margin-top: 89px;
}

.contenedor-libros {
	margin-top: 50px;
}

.texto-resumen-libro {
	font-size: 15px;
	font-weight: 400;
	font-family: Georgia;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: 0.6px;
	text-align: left;
	align-content: center;
}

@media screen and (min-width: 992px) {
	.texto-resumen-libro {
		font-size: 16.5px;
		font-weight: normal;
	}
}
