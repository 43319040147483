.card_indicador {
	border: 1px solid black;
	margin-bottom: 26px;
	border-radius: 0px;
}
.img_portada {
	cursor: pointer;
}

.boton_pdf {
	background-color: white;
	color: black;
	border: none;
	font-size: 16.5px;
	line-height: 1.7;
	letter-spacing: 0.39px;
	font-family: "GothicMain";
	padding: 0px;
}
.fecha_text {
	font-family: "GothicMainDemi";
}
.image_container {
	text-align: center;
	padding: 14px 14px 0 14px;
}

@media screen and (min-width: 576px) {
	.img_portada {
		border-radius: 6px;
		margin-bottom: 10px;
		cursor: pointer;
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 768px) {
	.img_portada {
		border-radius: 6px;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.image_container {
		text-align: center;
		padding: 23px 23px 0 23px;
	}
}

@media screen and (min-width: 992px) {
	.img_portada {
		border-radius: 6px;
		margin-bottom: 20px;
		cursor: pointer;
	}

	.image_container {
		text-align: center;
		padding: 23px 23px 0 23px;
	}
}

@media screen and (min-width: 1200px) {
	.img_portada {
		border-radius: 6px;
		margin-bottom: 20px;
		cursor: pointer;
	}
}
