.boton-mostrar-resultados {
	background-color: white;
	font-family: "GothicMain";
	border: none;
	color: black;
	font-size: 16.5px;
	box-sizing: content-box;
	justify-content: flex-start;

	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: 0.39px;
	color: var(--black);
}

.boton-mostrar-resultados-mobile {
	background-color: white;
	font-family: "GothicMain";
	border-radius: 8px;
	border: solid 1px black;
	color: black;
	font-size: 15px;
	box-sizing: content-box;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 11px;
	padding-right: 11px;

	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: 0.58px;
	color: var(--black);
}
