.contenedor-contenido-afice {
	display: flexbox;
}
.contenido-autor-afiche {
	font-family: "GothicMainBook";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.54;
	letter-spacing: 0.5px;
	max-width: 255px;
	margin-top: 7px;
}

@media screen and (max-width: 576px) {
	.imagen-afiche {
		width: 100%;
		object-fit: cover;
		border: solid 1px var(--very-light-grey);
		padding: 0px;
	}
	.contenido-lateral-afiche {
		margin-top: 46px;
		padding-left: 0px;
	}
}

@media screen and (min-width: 576px) {
	.imagen-afiche {
		width: 296px;
		height: 419px;
		object-fit: cover;
		border: solid 1px var(--very-light-grey);
	}
	.contenido-lateral-afiche {
		margin-top: 56px;
		padding-left: 0px;
	}
}
