.Main-image-buho {
	width: 1440px;
	height: 768px;
	padding: 23px 0 0;
	background-image: url("./assets/main-image-buho/main-image-buho.jpg");
}

.banner-image {
	background-image: url("./assets/main-image-buho/main-image-buho.jpg");
	background-size: cover;
}

.main-text {
	margin-left: 15%;
	width: 540px;
	height: 108px;
	font-family: "GothicMainDemi";
	font-size: 72px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: white;

	text-transform: uppercase;
}
.main-galleries {
	margin-top: 16px;
}

.link-component:hover {
	color: currentColor;
}
.link-component {
	text-transform: lowercase;
}

.links-container {
	margin-top: 89px;
	min-height: 30px;
}

h4,
h5,
h6 {
	font-family: "GothicMainBook";
}

a {
	text-decoration: none;
}

@font-face {
	font-family: "GothicMainDemi"; /*name to be used */
	src: url("./assets/Fonts-urw_gothic_L/urw_gothic_l_demi.ttf");
}

@font-face {
	font-family: "GothicMainBook";
	src: url("./assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}

@media screen and (min-width: 992px) {
	.container-sm {
		max-width: 1140px !important;
	}
}
