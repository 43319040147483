@media screen and (min-width: 576px) {
	.contenedor-flechas-pdf {
		margin-top: 53px;
	}
}

@media screen and (max-width: 576px) {
	.contenedor-flechas-pdf {
		margin-top: 26px;
	}
}

.contenedor-pdf {
	margin-top: 45px;
	border: 1px solid black;
	margin-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* height: 39.71vw; No cambiar este valor! */
}
.PDF-document {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* width: auto !important; */
	/* height: 40vh !important; */
	position: relative;
	z-index: 1;
}
.pdf-page canvas {
	max-width: 100%;
	width: auto !important;
	height: auto !important;
}
.contenedor-seccion-pdf {
	margin-top: 70px;
	/* margin-left: 10%; */
	/* margin-right: 10%; */
}
.contendor-page {
	height: 550px;
	width: 550px;
}

.botones-flechas-pdf {
	margin-left: 24px;
}
.boton-expandir {
	margin-left: 40px;
}
.boton-cerrar-pdf {
	margin-left: 40px;
}

.botones-pdf {
	background-color: white;
	color: black;
	border: none;
	font-size: 16.5px;
	line-height: 1.7;
	letter-spacing: 0.39px;
	font-family: "GothicMain";
	padding: 0px;
}

.flecha-descargar {
	transform: rotate(90deg);
	margin-right: 13px;
}

@font-face {
	font-family: "GothicMain"; /*name to be used */
	src: url("../../../../../assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}
