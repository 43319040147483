.noticia-card {
	border-radius: 0 !important;
	height: 150px;
	cursor: pointer;
}

.noticia-description {
	padding: 0px !important;
}

.texto-noticia {
	font-family: GothicMainDemi;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	text-transform: uppercase;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: #ea1340;
	margin: 0px;
	padding-bottom: 5px;
}
.texto_plazo {
	font-family: GothicMainDemi;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.6px;
	color: var(--black);
	margin: 0px;
	padding-bottom: 5px;
}

.categoria-noticia {
	padding: 18px;
	font-family: "GothicMainBook";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
	color: black;

	margin-bottom: 0px;
}

.categoria-noticia a {
	color: black;
}
.categoria-noticia a:hover {
	text-decoration: none;
}

.card-img.card-img-top.imagen-noticia {
	height: 150px;
	object-fit: cover;
	border-radius: 0px !important;
	width: 100px;
	filter: grayscale(1);
}

.noticiaRow {
	max-height: 150px;
	height: 100%;
	margin: 0;
	display: flex;
}

.noticiaCol {
	max-width: 100%;
	padding: 0px !important;
}

.noticia-titulo-card.card-title.h5 {
	max-height: 60px;
	font-size: 13px !important;
	margin-left: 0px;
	padding: 18px;
	font-family: "GothicMainDemi";
	font-weight: bold;
	line-height: 1.55;
	letter-spacing: 0.6px;
}

.noticia-fecha.card-text {
	border-left: 1px solid black;
	padding-top: 20px;
	padding-left: 15px;
	padding-right: 10px;
	height: 100%;
	min-width: 65px;
	font-family: "GothicMainDemi";
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.gallery:last-child {
	border: none;
}

.raro {
	justify-content: center;
}

.title_categories {
	display: flex;
	flex-direction: column;

	justify-content: space-between;
}
@media screen and (min-width: 576px) {
	.card-img.card-img-top.imagen-noticia {
		width: 100px;
	}
}

@media screen and (min-width: 768px) {
	.card-img.card-img-top.imagen-noticia {
		width: 120px;
	}
}

@media screen and (min-width: 992px) {
	.card-img.card-img-top.imagen-noticia {
		width: 140px;
	}
}
