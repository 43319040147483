#pdfGrande {
  display: none;
  z-index: 3;
  /* position: absolute; */
  top: 0;
  left: 0;
  /* right: 0; */
  /* margin-left: 0; */
  /* margin-right: 0; */
  /* width: 100vw; */
  /* height: 40vw; */
}
.PDF-document-grande {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto !important;
  height: 50vw !important;
  position: relative;
  z-index: 1;
}

.react-pdf__Document.rowGrande {
  /*Dar height exacto para cada view h= h conntenedor pddf - padding - marign*/
  /* min-height: 800px; */
  /* height: 800px; */
  /* width: 100%; */
}

.pdf-grande-page canvas {
  max-width: 90%;
  width: auto !important;
  height: auto !important;
  margin-left: 5%;
}

.pdfGrandeShadow {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
  /* margin-top: max(3%, 45px); */
  margin-bottom: 50px;
}
.contenedor-pdf-grande {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100vw;
  margin-top: 20px;
  margin-bottom: 50px;
}
.botones-pdf-grande {
  background-color: white;
  color: black;
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.flechasPdf {
  position: absolute;
  z-index: 4;
  margin-top: 25%;
  margin-left: 4%;
  display: flex;
}
.boton-flechas-pdf-grande-right {
  /* margin-left: auto; */
  right: 0;

  margin-right: -92vw;
}
.boton-flechas-pdf-grande-left {
  margin-right: auto;
  left: 0;
}
.boton-cerrar-libro-pdf-grande {
  top: 0;
  right: 0;
  margin-right: 5%;
}
