.big_nav_item {
	color: white;
	font-family: GothicMainBook;
	font-size: 22px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.52px;
	line-height: 0.75;
	margin-left: 7px;
	margin-bottom: 10px;
}

.border_bottom {
	border-bottom: 1px solid white;
}

.small_nav_item {
	color: "white";
	font-size: 15px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.35px;
	line-height: 1.1;
	margin: 20px 0 0 67px;
	text-align: left;
	width: 100%;
}

.arrow_flip {
	transform: rotate(180deg);
	position: absolute;
	margin-left: 10px;
}
.arrow_no_flip {
	position: absolute;
	margin-left: 10px;
}

.side_menu {
	background-color: #333333 !important;
	opacity: 1;
	width: 250px !important;
}

.programas_sub_menu {
	background-color: #333333 !important;
	opacity: 1;
	/* border-color: white !important; */
	border: 1px solid #333333 !important;
}

.sub_menu_item {
	font-family: GothicMainBook;
	font-size: 13px !important;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.5px;
	line-height: 1.38;
	margin-left: 10px;
}
.offcanvas_body_container {
	padding-top: 0px;
}
