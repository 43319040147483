.artesano-card {
	border-radius: 6px;
	cursor: pointer;
	border: none !important;
}

.categoria-artesano {
	padding-right: 10px;
	font-family: "GothicMain";
	font-size: 13px;
	margin-left: 0px;
	padding-left: 0px;
}

.titulo-card {
	font-size: 16.5px;
	margin-left: 0px;
	padding-left: 0px;
	font-family: "GothicMain";
	font-weight: bold;
}
.artesano-description {
	padding-left: 0px;
}

.imagen-artesano {
	border-radius: 6px;
	height: 312px;
	width: 255px;
	object-fit: cover;
}
.imagen-artesano-view {
	border-radius: 6px;
}
@font-face {
	font-family: "GothicMain"; /*name to be used */
	src: url("../../../assets/Fonts-urw_gothic_L/urw_gothic_l_book.ttf");
}
