@media screen and (min-width: 1600px) {
	.main-footer {
		color: white;
		background: linear-gradient(to right, #333333 61%, black 61%);
		padding-top: min(24px, 3%);
		padding-bottom: 0;
		position: relative;
		bottom: 0;
		width: 100%;
	}
}
@media screen and (min-width: 2100px) {
	.main-footer {
		color: white;
		background: linear-gradient(to right, #333333 58%, black 58%);
		padding-top: min(24px, 3%);
		padding-bottom: 0;
		position: relative;
		bottom: 0;
		width: 100%;
	}
}
@media screen and (max-width: 1600px) {
	.main-footer {
		color: white;
		background: linear-gradient(to right, #333333 64%, black 64%);
		padding-top: min(24px, 3%);
		padding-bottom: 0;
		position: relative;
		bottom: 0;
		width: 100%;
	}
}

/*
.main-footer .col {
  padding-left: 3.5em;

}
*/

.main-footer p {
	font-family: "GothicMainDemi";
	line-height: 1.19;
	/* font-size: 2.667vw; */
	font-size: 32px;

	font-weight: normal;

	font-stretch: normal;

	font-style: normal;
	letter-spacing: 0.75px;
}
img.footerLogo {
	width: 80%;

	height: 20%;

	margin-bottom: 8%;
	object-fit: contain;
}
li + li {
	margin-top: 8px;
}

ui + ui {
	margin-left: 37px;
}

a.unstyled-link {
	color: #ffffff !important;
	text-decoration: none;
}

a.unstyled-link:hover {
	text-decoration: none;
}

#social-icons-ul {
	position: absolute;
	bottom: 0 !important;
	margin-bottom: 8px;
}

#arrowReg {
	margin-left: 1em;
}

#minLogo {
	border-right: 1px solid rgb(255, 255, 255);

	padding-right: 0px;
	margin-bottom: min(17px, 2%);
}
#minLogo ul li p {
	font-family: "GothicMainBook";
	/* font-size: 1.083vw; */
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.62;
	letter-spacing: normal;
	white-space: pre-line;
}
#to-register {
	position: relative;
}
.registerDiv {
	left: 5%;
	position: relative;
}

#to-register ul {
	font-size: 1.375vw;
}

#to-register p {
	margin-bottom: 9% !important;
}
/* */
.pointer-footer {
	cursor: pointer;
}
