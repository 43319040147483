.afiche-card {
	margin-bottom: 26px;
	border: solid 1px var(--very-light-grey);
	cursor: pointer;
}

.afiche-img {
	width: 100%;
}

.p-unstyled {
	margin: 0px;
	padding: 0px;
}

.texto-resumen-afiche {
	font-size: 15px;
	font-weight: 400;
	font-family: Georgia;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: 0.6px;
	text-align: left;
	align-content: center;
}

@media screen and (min-width: 992px) {
	.texto-resumen-afiche {
		font-size: 16.5px;
		font-weight: normal;
	}
}
