/* Standard Navbar*/
@media screen and (min-width: 992px) {
	.dropdown-toggle.nav-link {
		line-height: 1.9 !important;
	}

	.navbar-expand-lg .navbar-nav .subMenu .dropdown-menu {
		margin-top: min(10px, 6%);
		margin-bottom: min(10px, 6%);
		background-color: #333333;
		position: static !important;
		margin-left: 0;
		min-width: 1rem !important;
		border-radius: 0;
		border: none;
		padding-top: 0px;
		padding-bottom: 0px;
		transform: translateX(0px);
	}
	/*Navbar Dropdowns*/

	.navbar-expand-lg .navbar-nav .dropdown-item:hover {
		background-color: transparent !important;
		color: #fff;
	}

	.dropdown {
		display: flex;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		border-radius: 6px !important;
		background-color: #333333;
		min-width: 1rem !important;
		border-radius: 0;
		border: none;
		padding-top: 0;

		transform: translateX(-20px);
	}

	/*Dropdowns triangles*/
	.navbar-expand-lg .navbar-nav .dropdown-menu.show:before {
		content: ""; /* Required to display content */
		position: absolute; /* Sets the position absolute to the top div */
		top: 0;
		left: min(30px, 20%); /* position the little arrow */

		margin-top: -10px; /* Set margin equal to border px */

		width: 0;
		z-index: 1;
		height: 0;
		border-bottom: solid 15px #333; /* Creates the arrow pointing up, to change to a notch instead user border-top */
		border-left: solid 10px transparent; /* Creates triangle effect */
		border-right: solid 10px transparent; /* Creates triangle effect */
	}
	.languages.nav-item.dropdown .dropdown-menu {
		margin-left: 56%;
	}
	.languages.nav-item.dropdown .dropdown-menu a {
		padding: 10px 9px 5px 9px;
	}
	.navbar-expand-lg .navbar-nav .nav-item,
	.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0 !important;
		/*margin-top: 13px;*/
		margin-top: 9px;
	}
	.scrolling-active-links {
		color: #000 !important;
	}

	.scrolling-active-links > a {
		color: #000 !important;
	}

	.scrolling-active-links > .nav-item::before {
		color: #000 !important;
	}
	.scrolling-active-links .dropdown > a {
		color: #000 !important;
	}
	.scrolling-active-links a a {
		color: #fff !important;
	}
	.scrolling-active-links .dropdown .dropdown a {
		color: #fff !important;
	}
	.navbar-light .navbar-nav .link-navbar,
	.navbar-light .navbar-nav .nav-link {
		font-family: "GothicMainDemi";
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.87;
		letter-spacing: normal;
	}
	#navDropdown-Progs {
		font-family: GothicMainBook;
		padding: 4px 9px 4px 9px !important;
		font-size: 15px;
		/* margin: 0 !important; */
		margin-top: 5px;
	}
}

/* Mobile Navbar */
@media screen and (max-width: 991px) {
	#navLinks .link-navbar.keyboard {
		display: none;
	}
	#navDropdown-Progs.keyboard {
		display: none;
	}

	.navbar-light .navbar-toggler {
		border: none;
	}
	.navbar-light .navbar-toggler-icon {
		background-image: url(../../assets/icons/mobileMenu/hamburguer-menu-white.svg);
	}
	.navbar-dark .navbar-toggler-icon {
		background-image: url(../../assets/icons/mobileMenu/hamburguer-menu-black.svg);
	}
	.navbar-toggler:focus {
		box-shadow: none !important;
	}

	.menu-toggler {
		z-index: 0;
	}
	.languages.nav-item.show.dropdown {
		position: absolute;
		top: 0;
		left: 0;
	}
	.languages .dropdown-menu .dropdown-item:first-child {
		margin-top: 50px;
		font-size: 22px;
		line-height: 0.75;
	}
	.languages .dropdown-menu .dropdown-item:hover {
		background-color: transparent;
		text-decoration: none;
		color: white;
	}
	.languages .dropdown-menu .dropdown-item {
		text-align: center;
		width: 100%;
		left: 0;
		right: 0;
		margin-right: auto;
		margin-left: auto;
		position: relative;
		font-family: GothicMainBook;
		font-size: 15pxpx;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.1;
		letter-spacing: 0.52px;
	}
	.languages .dropdown-menu .dropdown-item::after {
		content: "";
		display: block;
		width: 345px;
		height: 1px;
		margin: 15px 3px 8px 0;
		border: solid 0.5px #979797;
		left: 0;
		right: 0;
		margin-right: auto;
		margin-left: auto;
		position: relative;
	}

	.navbar-expand-lg > .container,
	.navbar-expand-lg > .container-fluid,
	.navbar-expand-lg > .container-lg,
	.navbar-expand-lg > .container-md,
	.navbar-expand-lg > .container-sm,
	.navbar-expand-lg > .container-xl {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.cerrar-toggler {
		border: none;
		right: 0;
		top: 0;
		position: absolute;
		margin: 3px 0 50px 6px;
		object-fit: contain;
	}
	.cerrar-toggler .navbar-toggler-icon {
		width: 24px;
		height: 24px;
		background-image: url(../../assets/icons/mobileMenu/hamburguer-menu-cerrar.svg) !important;
	}
	.cerrar-langs {
		border: none;
		right: 0;
		margin: 21px 12px 0 0;
		top: 0;
		position: absolute;
		object-fit: contain;
		width: 24px;
		height: 24px;
		background-image: url(../../assets/icons/mobileMenu/hamburguer-menu-cerrar.svg) !important;
	}
	.cerrar-langs:hover {
		cursor: pointer;
	}
	.navbar {
		/* padding: 0 !important; */
		max-height: 64px !important;
		padding-top: 10px;
	}
	/* Apply styling to navbar when scrolling */
	.scrolling-active {
		background-color: white !important;
		border-bottom: 1px solid black;
	}
	.imagen-logo-navbar {
		margin-top: 0 !important;
		z-index: -2;
		position: relative;
	}
	.navBarLogo.navbar-brand {
		/* margin-top: 20px;*/
		margin-bottom: 15px;
	}
	.navbar-nav {
		margin-top: 15px;
		margin-bottom: 0;
		right: 0;
	}

	.navbar-expand-lg .navbar-nav > .nav-item::before {
		content: none !important;
	}
	Nav a + a::before,
	Nav .link-navbar + .link-navbar::before {
		content: none !important;
	}
	#responsive-navbar-nav {
		background-color: #333333;
		opacity: 1;
		z-index: 1;

		position: absolute;
		right: 0;
		top: 0;
		height: 100vh;
		/*width: 65vw;*/
		width: 240px;
	}
	#navLinks {
		align-items: center;
		font-family: GothicMainBook;
		font-size: 22px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 0.75;
		letter-spacing: 0.52px;
		color: white;
	}

	#navLinks .cerrar-toggler + .link-navbar {
		margin-top: 50px !important;
	}

	#navLinks .link-navbar {
		margin: 10px 25px 0 38px;
	}
	#navLinks .subMenu {
		width: 100%;
		margin: 10px 0 0 65px;
	}
	.subMenu .dropdown-menu.show {
		border: none;
		margin-right: 33px !important;
	}
	.subMenu .dropdown-item:hover {
		background-color: transparent;
		text-decoration: none;
	}
	#navLinks .link-navbar::after {
		content: "";
		display: block;
		width: 187px;
		height: 1px;
		margin: 11px 0 0px 0px;
		border: solid 0.5px #979797;
	}
	.institucional-links {
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: 0.35px;
		color: white;
		padding: 16px 25px 0 33px;
		width: 100%;
		text-align: left;
		flex-wrap: wrap;
	}
	.institucional-links:hover {
		text-decoration: none !important;
		background-color: transparent;
	}
	.dropdown-menu {
		background-color: #333333;
		opacity: 1;
		border: none !important;
	}
	.languages.nav-item.dropdown {
		margin-right: 32px;
	}
	.languages .dropdown-menu {
		position: absolute;
		margin-top: 0;
		top: 0;
		left: 0;
		width: 100vw;
		height: 225px;
		padding: 18px 12px 19px 15px;
		box-shadow: -1px 0 6px 0 rgba(0, 0, 0, 0.5);
		background-color: #333333;
		z-index: 1;
	}
	#navDropdown-Lang {
		font-family: "GothicMainDemi";
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.87;
		letter-spacing: normal;
	}
	.languages-scrolling {
		color: #000 !important;
	}
	.languages-scrolling > a {
		color: #000 !important;
	}
	/*Apply when whiteview*/
	.languages.nav-item.dropdown-whiteviews {
		color: #fff;
	}

	.scrolling-active-links > a {
		color: #ffff !important;
	}

	.scrolling-active-links > .nav-item::before {
		color: #ffff !important;
	}
	.scrolling-active-links .dropdown > a {
		color: #ffff !important;
	}
	.scrolling-active-links a a {
		color: #fff !important;
	}
	.scrolling-active-links .dropdown .dropdown a {
		color: #fff !important;
	}
	#mobileNavSearch {
		width: 100%;
		display: flex;
		margin: 20px 0 0 67px;
		object-fit: contain;

		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.1;
		letter-spacing: 0.35px;
		color: white;
	}
	#navDropdown-Progs {
		color: white;
	}
	#mobileNavShadow {
		display: block;
		position: absolute;
		z-index: -1;
		width: 100vw;
		height: 100vh;
		left: 0;
		right: 0;
		background-color: #000;
		opacity: 0.8;
		top: 0;
	}
}
/*******************************************/
.navbar {
	/* padding: 0 !important; */
	max-height: 53px;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
	align-items: unset;
}
.img.navBarLogo {
	max-width: 125px;
	max-height: 30px;
	margin: 23px 9.2px 36.5px 165px;
	object-fit: contain;
	display: flex;
	align-items: center;
}

.imagen-logo-navbar {
	/*margin-top: 21px;*/
	margin-top: 15px;
}
/* Navbar rules */

.link-navbar {
	padding: 0 !important;
	/*margin-top: 25px !important;*/
	margin-top: 19px !important;
	margin-left: 20px;
}

.link-navbar:hover {
	text-decoration: none !important;
	background-color: transparent;
}

.nav-institucional {
	font-family: "GothicMainDemi";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.87;
	letter-spacing: normal;
}

.scrolling-unactive-links a {
	color: #ffffff;
}

/* Apply styling to navbar when scrolling */
.scrolling-active {
	background-color: white !important;
	/* box-shadow: 0 3px 1rem rgba(0, 0, 0, 0.1);*/
}

.navbar-expand-lg .navbar-nav .dropdown-menu a + a:before {
	content: "";
	padding: 0;
	margin: 0;
}

.navbar-expand-lg .navbar-nav .dropdown-item a:hover {
	text-decoration: none;
}
.navbar-expand-lg .navbar-nav .dropdown-toggle::after {
	display: none;
}

a.dropdown-item {
	font-family: GothicMainBook;
	color: #ffffff;
	padding: 4px 9px 4px 9px;
	font-size: 15px;
}

a.dropdown-item:focus {
	font-family: GothicMainBook;
	color: #ffffff;
	padding: 4px 9px 4px 9px;
	font-size: 15px;
}

#navDropdown-Progs #programasIcon {
	font-size: 13px;
	transform: scaleY(-1);
	display: inline-block;
}
.arrow-flip {
	transform: scaleY(1) !important;
}
#navDropdown-Progs.dropdown-toggle::after {
	/* display: inline-block !important;*/
	display: none;
}
.dropdown-closed {
	content: "^";
}

#navDropdown-Progs.dropdown-toggle::before {
	content: "";
	padding: 0 !important;
}

.navbar-expand-lg .navbar-nav .subMenu .dropdown-menu a.dropdown-item {
	max-width: 140px;
	font-size: 13px !important;
	font-family: GothicMainBook;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
	padding: 0;
	margin: 0 20px 0 20px;
}

.navbar-expand-lg
	.navbar-nav
	.subMenu
	.dropdown-menu
	a.dropdown-item
	+ a.dropdown-item {
	margin-top: 11px;
}

.subMenu.nav-item.show.dropdown {
	display: list-item;
}

.TextoEnlinea {
	white-space: pre-line;
}

.white-nav,
.white-nav:active,
.white-nav:hover,
.white-nav:focus {
	color: #ffffff;
	font-family: "GothicMainDemi";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.87;
	letter-spacing: normal;
}

.black-nav,
.black-nav:active,
.black-nav:hover,
.black-nav:focus {
	color: #000000;
	font-family: "GothicMainDemi";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.87;
	letter-spacing: normal;
}

.white-nav .dropdown-toggle.nav-link {
	color: white !important;
}

.white-nav .nav-link ::before {
	color: white !important;
}

.black-nav .dropdown-toggle.nav-link {
	color: black;
}
.black-nav .nav-link ::before {
	color: black;
}

.black-nav .nav-item .dropdown ::before {
	color: black !important;
}

.nav_divider {
	font-family: "GothicMainDemi";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.87;
	letter-spacing: -2px;
	padding: 17px 17px;
}

.subMenu_toggle {
	margin-left: 20px;
}

.border-bottom-black {
	border-bottom: 1px solid black;
}
