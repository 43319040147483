.resultadoRow {
	height: 182px;
	height: 100%;
	margin: 0;
	display: flex;
}

.resultadoCol {
	padding-left: 0px !important;
}

.card.resultado-card {
	height: 182px;
	border: none !important;
	border-radius: 0 !important;
	display: flex;
	cursor: pointer;
}
.card-img-top.imagen-resultado {
	height: 180px;
	object-fit: cover;
	border-radius: 6px;
	width: 110px;
}
.resultado-description {
	height: 100%;
	padding: 0px !important;
	max-width: 100%;
}

.resultado-description.card-body {
	height: 100%;
	margin: 0;
	font-family: Georgia;
	font-size: 16.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: 0.6px;
	color: var(--black);
}

.resultado-titulo-card.card-title.h5 {
	height: 26px;
	font-family: "GothicMainDemi";
	font-size: 16.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: var(--black);
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
	text-overflow: ellipsis;
}

p.fecha-resultado.card-text {
	max-height: 15px;
	display: flex;
	align-items: center;
}
span.subtexto-resultado {
	margin-bottom: 0 !important;
	font-family: Georgia;
	font-size: 16.5px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: 0.6px;
	color: var(--black);
	overflow: hidden;
	text-overflow: ellipsis;
	/* white-space: nowrap; */
	/* display: inline-block; */
}

label.resultado-fecha {
	/* bottom: 0; */
	/* left: 0; */
	height: 18px;
	margin: 0 !important;
	font-family: GothicMainBook;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: 0.5px;
	color: var(--black);
}
.texto-resultado {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.result-text-container {
	display: flex;
	flex-direction: column;
	height: 182px;
	margin-left: 13px;
	justify-content: space-between;
}

@media screen and (min-width: 576px) {
	.resultadoRow {
		margin: 0;
		display: flex;
		flex-direction: row;
	}
}

@media screen and (min-width: 992px) {
	.resultado-description {
		max-width: 60%;
	}
}
